import React, { useState, useEffect } from 'react'
import Form from "../../layouts/Form";
import { useForm } from '../../hooks/useForm';
import { Grid, InputAdornment, makeStyles, ButtonGroup, Button as MuiButton } from '@material-ui/core';
import { Input, Select, Button } from "../../controls";
import ReplayIcon from '@material-ui/icons/Replay';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import ReorderIcon from '@material-ui/icons/Reorder';
import { createAPIEndpoint, ENDPIONTS } from "../../api";
import { roundTo2DecimalPoint } from "../../utils";
import Popup from '../../layouts/Popup';
import Notification from "../../layouts/Notification";


const useStyles = makeStyles(theme => ({
    adornmentText: {
        '& .MuiTypography-root': {
            color: '#f3b33d',
            fontWeight: 'bolder',
            fontSize: '1.5em'
        }
    },
    submitButtonGroup: {
        backgroundColor: '#f3b33d',
        color: '#000',
        margin: theme.spacing(1),
        '& .MuiButton-label': {
            textTransform: 'none'
        },
        '&:hover': {
            backgroundColor: '#f3b33d',
        }
    }
}))

export default function ProductForm(props) {
    const [foodItemName, setFoodItemName] = useState("");
    const [price, setPrice] = useState("");
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetFormControls
    } = props;

    const classes = useStyles();

    const [notify, setNotify] = useState({ isOpen: false })

    const validateForm = () => {
        let temp = {};
        temp.foodItemName = foodItemName.length > 0 ? "" : "This field is required.";
        temp.price = price != 0 ? "" : "This field is required.";
        return Object.values(temp).every(x => x === "");
    }

    const submitForm = e => {
        e.preventDefault();
        if (validateForm()) {
            let values = {"foodItemName": foodItemName, "price": price};
            createAPIEndpoint(ENDPIONTS.FOODITEM).create(values)
                    .then(res => {
                        console.log(res);
                        window.location.href = "/products";
                        
                    })
                    .catch(err => {
                        console.log(err);
                        setNotify({isOpen:true, message:'Bir sorun oluştu.'});
                    });
        }

    }

    return (
        <>
            <Form onSubmit={submitForm}>
                <Grid container>
                    <Grid item xs={6}>
                        <Input
                            label="Ürün Adı"
                            name="foodItemName"
                            value={foodItemName}
                            onChange={(e) => setFoodItemName(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment
                                    className={classes.adornmentText}
                                    position="start">#</InputAdornment>
                            }}
                        />
                        <Input
                            label="Fiyatı"
                            name="price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment
                                    className={classes.adornmentText}
                                    position="start">₺</InputAdornment>
                            }}
                        />
                        <Button className={classes.submitButtonGroup}
                            size="large"
                            type="submit"
                        >Kaydet</Button>
                    </Grid>
                 
                </Grid>
            </Form>
            <Notification
                {...{ notify, setNotify }} />
        </>
    )
}
