import axios from "axios";

//const BASE_URL = 'http://localhost:5000/api/';

const BASE_URL = 'https://abalirestaurant.azurewebsites.net/api/';

export const ENDPIONTS = {
    CUSTOMER: 'Customer',
    FOODITEM: 'FoodItem',
    ORDER: 'Order',
    AUTH_LOGIN:'auth/login',
    AUTH_REGISTER:'auth/register'
}

export const createAPIEndpoint = endpoint => {
    let token = localStorage.getItem('accessToken');
    let url = BASE_URL + endpoint + '/';
    const headers = {
        'Content-Type':'application/json',
        'Authorization': 'Bearer ' + token
    }
    return {
        fetchAll: () => axios.get(url, {headers:headers}),
        fetchById: id => axios.get(url + id, {headers:headers}),
        create: newRecord => axios.post(url, newRecord, {headers:headers}),
        login: newRecord => axios.post(url, newRecord),
        update: (id, updatedRecord) => axios.put(url + id, updatedRecord, {headers:headers}),
        delete: id => axios.delete(url + id, {headers:headers})
    }
}