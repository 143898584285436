import React, { useState, useEffect } from 'react'
import Form from "../../layouts/Form";
import { Grid, InputAdornment, makeStyles, ButtonGroup, Button as MuiButton } from '@material-ui/core';
import { Input, Select, Button } from "../../controls";
import ReplayIcon from '@material-ui/icons/Replay';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import ReorderIcon from '@material-ui/icons/Reorder';
import { createAPIEndpoint, ENDPIONTS } from "../../api";
import { roundTo2DecimalPoint } from "../../utils";
import { CenterFocusStrong } from '@material-ui/icons';
import Notification from "../../layouts/Notification";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    adornmentText: {
        '& .MuiTypography-root': {
            color: '#f3b33d',
            fontWeight: 'bolder',
            fontSize: '1.5em'
        }
    },
    submitButtonGroup: {
        backgroundColor: '#f3b33d',
        color: '#000',
        margin: theme.spacing(1),
        '& .MuiButton-label': {
            textTransform: 'none'
        },
        '&:hover': {
            backgroundColor: '#f3b33d',
        }
    },
    loginForm: {
        textAlign: 'center'
    },
}))

export default function Login(props) {
    const history = useHistory();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const classes = useStyles();

    const validateForm = () => {
        let temp = {};
        temp.userName = userName.length > 0 ? "" : setNotify({ isOpen: true, message: 'Lütfen bilgilerinizi kontrol edin.' });
        temp.password = password.length > 0 ? "" : setNotify({ isOpen: true, message: 'Lütfen bilgilerinizi kontrol edin.' });
        temp.email = email.length > 4 ? "" : setNotify({ isOpen: true, message: 'Lütfen bilgilerinizi kontrol edin.' });
        temp.phone = phone.length > 0 ? "" : setNotify({ isOpen: true, message: 'Lütfen bilgilerinizi kontrol edin.' });
        temp.companyName = companyName.length > 0 ? "" : setNotify({ isOpen: true, message: 'Lütfen bilgilerinizi kontrol edin.' });

        return Object.values(temp).every(x => x === "");
    }
    const [notify, setNotify] = useState({ isOpen: false })

    const submitLogin = e => {
        e.preventDefault();
        if (validateForm()) {
            let values = { "Username": userName, "Password": password, "CompanyName": companyName, "Email": email, "Phone": phone };
            createAPIEndpoint(ENDPIONTS.AUTH_REGISTER).login(values)
                .then(res => {
                    console.log(res);
                    if (res.data == "success") {
                        setNotify({ isOpen: true, message: 'Kaydınız başarıyla yapıldı. Giriş yapabilirsiniz.' });
                        setTimeout(window.location.href = "/login",5000);
                    }
                    if(res.data == "used"){
                        setNotify({ isOpen: true, message: 'Bu kullanıcı adı ve/veya email kullanımda' });
                    }
                })
                .catch(err => {
                    console.log(err);
                    setNotify({ isOpen: true, message: 'Bir sorun oluştu.' });
                });
        }
    }

    return (
        <>
            <Form onSubmit={submitLogin}>
                <Grid container>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            label="Kullanıcı Adı"
                            name="userName"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment
                                    className={classes.adornmentText}
                                    position="start">#</InputAdornment>
                            }}
                        />
                        <Input
                            label="Şifre"
                            name="password"
                            value={password}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment
                                    className={classes.adornmentText}
                                    position="start">#</InputAdornment>
                            }}
                        />
                        <Input
                            label="Email"
                            name="email"
                            value={email}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment
                                    className={classes.adornmentText}
                                    position="start">#</InputAdornment>
                            }}
                        />
                         <Input
                            label="Telefon"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment
                                    className={classes.adornmentText}
                                    position="start">#</InputAdornment>
                            }}
                        />
                          <Input
                            label="Şirket Adı"
                            name="companyName"
                            value={companyName}
                            onChange={(e) =>setCompanyName(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment
                                    className={classes.adornmentText}
                                    position="start">#</InputAdornment>
                            }}
                        />
                        <Button
                            size="large"
                            type="submit"
                            className={classes.submitButtonGroup}
                        >Kayıt Ol</Button>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                </Grid>
            </Form>
            <Notification
                {...{ notify, setNotify }} />
        </>
    )
}
