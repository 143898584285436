import { AppBar, Toolbar } from "@material-ui/core"
import { Home, LaptopWindows } from "@material-ui/icons"
import { IconButton, List, ListItem, ListItemText, Container,Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { useHistory } from "react-router-dom";
import { Input, Select, Button } from "../../controls";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
const useStyles = makeStyles({
  navbarDisplayFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    
  },
  navDisplayFlex: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  linkText: {
    textDecoration: `none`,
    color: 'black',
    fontWeight: 'bolder',
    fontSize: '1.5em',
  },
  containerName:{
    marginTop:'15px',
    marginBottom: '15px',
    
  },
  linkInnerText:{
    padding:'10px',
    backgroundColor:'#f3b33d'
  },
  linkLogoutText:{
    padding:'10px',
    backgroundColor:'#e0e0e0'
  }
});


export default function Layout() {
  const history = useHistory();

  const logout = e => {
    e.preventDefault();
    localStorage.clear();
    window.location.href = "/";
}

  let token = localStorage.getItem('accessToken');
  let navLinks = [
    { title: 'Kontrol Paneli', path: '/dashboard' },
    { title: 'Müşteriler', path: '/customer' },
    { title: 'Ürünler', path: '/products' },
  ]
  if(token == null){
    navLinks = [
      { title: 'İletişim', path: '/contact' },
      { title: 'Kayıt Ol', path: '/register' },
      { title: 'Giriş Yap', path: '/login' },
    ]
  }

  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.containerName}>
      <Toolbar>
        <Container maxWidth="md" className={classes.navbarDisplayFlex}>
          <IconButton edge="start" href="/dashboard" color="inherit" aria-label="home">
            <Home fontSize="large" />
          </IconButton>
          
          <List
            component="nav"
            aria-labelledby="main navigation"
            className={classes.navDisplayFlex}
          >
            {navLinks.map(({ title, path }) => (
              <a href={path} key={title} className={classes.linkText}>
                <ListItem button >
                  <ListItemText primary={title} className={classes.linkInnerText}/>
                </ListItem>
              </a>
            ))}
            {
              token? (
              <a className={classes.linkText} onClick={logout}>
                <ListItem button>
                  <ListItemText primary="Çıkış Yap" className={classes.linkLogoutText}/>
                </ListItem>
              </a>):(<p></p>)
            }
          </List>
        </Container>
      </Toolbar>
    </AppBar>
  )
}