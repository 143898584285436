import logo from './logo.svg';
import './App.css';
import { Container, Typography } from "@material-ui/core";
import React, {useState} from 'react';
import Order from "./components/Order";
import Login from "./components/User/Login";
import Register from "./components/User/Register";
import Header from "./components/Layout/Header";
import Customer from "./components/Layout/Customer";
import Products from "./components/Layout/Products";
import ProductForm from "./components/Layout/ProductForm";
import CustomerForm from "./components/Layout/CustomerForm";
import Home from "./components/Layout/Home";
import Contact from "./components/Layout/Contact";

import { BrowserRouter as Router, Route } from 'react-router-dom';

function App() {
  
  return (
    <Container maxWidth="md">
      <Header /> 
    
      <Router>
             <Route exact path='/login' component={Login} />
             <Route exact path='/register' component={Register} />
             <Route exact path='/' component={Home} />
             <Route exact path='/contact' component={Contact} />
             <Route exact path='/dashboard' component={Order} />
             <Route exact path='/customer' component={Customer} />
             <Route exact path='/products' component={Products} />
             <Route exact path='/addproduct' component={ProductForm} />
             <Route exact path='/addcustomer' component={CustomerForm} />
         </Router>
      {/* <Order /> */}
    </Container>
  );
}

export default App;
