import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });
export default function Home() {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
  const linkStarted = () => {
    window.location.href="/register";
  }
    return (
        <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Dünyanın en kolay ve en kullanışlı sipariş yönetim yazılımına hoşgeldin!
          </Typography>
          <Typography variant="h5" component="h2">
          Abalı Restaurant Uygulaması
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Ücretsiz olarak kayıt ol ve hemen siparişlerini yönetmeye başla..
          </Typography>
          <Typography variant="body2" component="p">
            Müşterilerini ekleyebilir, ürünlerini ekleyebilir siparişlerini yönetebilirsin. Pratik ve ücretsiz sipariş yönetim programını sizde hemen ücretsiz kullanın. Tüm sorularınız için veya bize destek olmak isterseniz info@abali.net'e mail atabilirsiniz.
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={linkStarted}>Hemen Başla</Button>
        </CardActions>
      </Card>
    )
}
